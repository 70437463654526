import { IconButton, Typography, Tooltip } from "@mui/material";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAppContext } from "src/hooks";
import MessageMenu from "./MessageMenu";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config";
import { AudioPlayer, Iconify, UserAvatar } from "src/components";
import { capitalize } from "lodash";
import TempReply from "./TempReply";
import ReplyIcon from "@mui/icons-material/Reply";

function MessageItem({
  hoveredMessageId,
  isReplyIconVisible,
  repliedMessage,
  setRepliedMessage,
  setReplyMessage,
  loadMoreMessages,
  isDateSame,
  message,
  setIsEditingMessage,
  setNewMessageText,
  setTargetEditMessage,
  nextMessage,
}) {
  const { dispatch_get_user_profile } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReply, setIsReply] = useState(true);

  useEffect(() => {
    if (repliedMessage) {
      setTimeout(() => {
        setRepliedMessage();
      }, 3000);
    }
  }, [repliedMessage]);

  if (message.sender_id == dispatch_get_user_profile().user_id) {
    return (
      <div>
        {(message.sender_id !== nextMessage?.sender_id || !isDateSame) && (
          <div className="sender-message-position">
            <div className="sender-message-info">
              <Typography className="sender-text">You</Typography>
              <div className="sender-avatar">
                <UserAvatar
                  image={message.message_sender.image}
                  name={
                    message?.message_sender?.first_name +
                    " " +
                    message?.message_sender?.last_name
                  }
                  sx={{ width: "28px", height: "28px", mr: 1 }}
                  alt="sender"
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-end message-margin`}
        >
          <div
            className={`mr-1 sent-message message-item reply-effect-container ${
              repliedMessage?._id === message._id ? "reply-effect" : ""
            }`}
            style={{
              marginTop:
                message?.sender_id == nextMessage?.sender_id && isDateSame
                  ? "0.1rem"
                  : "1rem",
            }}
          >
            {isReplyIconVisible && hoveredMessageId === message._id && (
              <div className="send-reply-btn">
                <Tooltip
                  arrow
                  title="You can also reply to Message by Double clicking on it."
                >
                  <IconButton
                    className="textarea-btn mx-2"
                    color="secondary"
                    onClick={() => {
                      setReplyMessage(message);
                      console.log(message);
                    }}
                  >
                    <ReplyIcon className="voice-icon" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {message?.reply_message_detail && (
              <TempReply
                message={message}
                loadMoreMessages={loadMoreMessages}
                setRepliedMessage={setRepliedMessage}
              />
            )}
            {message.image && !message.audio_duration && (
              <>
                <a href={s3baseUrl + message.image} target="_blank">
                  <img
                    src={s3baseUrl + message.image}
                    alt={"image"}
                    className="chat-item-img"
                  />
                </a>
                <Typography fontSize={12} className="clickable-link">
                  {message.text}
                </Typography>
              </>
            )}
            {message.image && message.audio_duration && (
              <div className="pt-2">
                <AudioPlayer
                  duration={message.audio_duration}
                  audioUrl={s3baseUrl + message.image}
                />
              </div>
            )}
            {!message.image && (
              <Typography className="clickable-link">
                <div>{message.text}</div>
              </Typography>
            )}
            <div>
              <Typography className="chat-date">
                {moment(message.createdAt).format("hh:mm A")}
              </Typography>
            </div>
          </div>
          <div className="message-options">
            {message._id && (
              <IconButton size="small" onClick={(e) => setAnchorEl(e.target)}>
                <MoreVertIcon height="10px" width="10px" />
              </IconButton>
            )}

            {!message._id && (
              <IconButton size="small">
                <AccessTimeIcon height="10px" width="10px" />
              </IconButton>
            )}
          </div>
        </div>

        <MessageMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          message={message}
          setIsEditingMessage={setIsEditingMessage}
          setNewMessageText={setNewMessageText}
          setTargetEditMessage={setTargetEditMessage}
        />
      </div>
    );
  } else {
    return (
      <div>
        {/* Show sender info when the sender changes or the date is different */}
        {(message.sender_id !== nextMessage?.sender_id || !isDateSame) && (
          <div className="sender-message-position mt-4">
            <div className="receiver-message-info">
              <div className="receiver-avatar">
                <UserAvatar
                  image={message.message_sender.image}
                  name={`${message?.message_sender?.first_name} ${message?.message_sender?.last_name}`}
                  sx={{ width: "28px", height: "28px", mr: 1 }}
                  alt="sender"
                />
              </div>
              <Typography className="sender-text">
                {capitalize(message?.message_sender?.first_name)}
              </Typography>
            </div>
          </div>
        )}

        {/* Main message container */}
        <div
          className="d-flex align-items-center message-margin card-positioning justify-content-start"
          style={{
            marginTop:
              message.sender_id === nextMessage?.sender_id && isDateSame
                ? "1rem"
                : "0.1rem",
          }}
        >
          <div className="d-flex">
            <div
              className="d-flex align-items-end"
              style={{ width: "38px" }}
            ></div>

            <div
              className={`ml-1 message-item received-message reply-effect-container ${
                repliedMessage?._id === message._id ? "reply-effect" : ""
              }`}
              style={{
                marginTop:
                  message.sender_id === nextMessage?.sender_id && isDateSame
                    ? "0rem"
                    : "1rem",
              }}
            >
              {/* Reply button */}
              {isReplyIconVisible && hoveredMessageId === message._id && (
                <div className="reply-btn">
                  <Tooltip
                    arrow
                    title="You can also reply to Message by Double clicking on it."
                  >
                    <IconButton
                      className="textarea-btn mx-2"
                      color="secondary"
                      onClick={() => {
                        setReplyMessage(message);
                        console.log(message);
                      }}
                    >
                      <ReplyIcon className="voice-icon" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {/* Reply Message */}
              {message?.reply_message_detail && (
                <TempReply
                  message={message}
                  loadMoreMessages={loadMoreMessages}
                  setRepliedMessage={setRepliedMessage}
                />
              )}

              {/* Image Message */}
              {message.image && !message.audio_duration && (
                <>
                  <a href={s3baseUrl + message.image} target="_blank">
                    <img
                      src={s3baseUrl + message.image}
                      loading="lazy"
                      alt="image"
                      className="chat-item-img"
                    />
                  </a>
                  <Typography fontSize={12} className="clickable-link">
                    {message.text}
                  </Typography>
                </>
              )}

              {/* Audio Message */}
              {message.image && message.audio_duration && (
                <div className="pt-2">
                  <AudioPlayer
                    duration={message.audio_duration}
                    audioUrl={s3baseUrl + message.image}
                  />
                </div>
              )}

              {/* Text Message */}
              {!message.image && (
                <Typography className="clickable-link">
                  {message.text}
                </Typography>
              )}

              {/* Timestamp */}
              <div>
                <Typography className="chat-date">
                  {moment(message.createdAt).format("hh:mm A")}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageItem;
