import { Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import { useAppContext } from "src/hooks";
import ActivityInfoDialog from "./ActivityInfoDialog";
import { UserAvatar } from "src/components";

export default function Activity({ task }) {
  const { dispatch_get_user_profile } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleOpenDialog = (activity) => {
    if (
      activity.type === "priority_update" ||
      activity.type === "target_date_update" ||
      activity.type === "status_update"
    ) {
      setSelectedActivity(activity);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedActivity(null);
  };

  const getActivityText = (activity) => {
    let text = "";

    if (activity.type === "priority_update") {
      text = "update task priority.";
    } else if (activity.type === "target_date_update") {
      text = "update task target date.";
    } else if (activity.type === "status_update") {
      text = "update task status";
    } else if (activity.type === "task_update") {
      text = "updated the task";
    } else if (activity.type === "team_update") {
      text = "updated task team";
    } else if (activity.type === "attachments_update") {
      text = "update the attachment";
    } else if (activity.type === "checklist_name_update") {
      text = "update task checklist name";
    } else {
      text = activity.text;
      return text;
    }

    return activity.text;

    const text_with_profile = (
      <>
        <b>
          {`${activity.action_by.first_name} ${activity.action_by.last_name}`}
        </b>
        <span>{text}</span>
      </>
    );

    return text_with_profile;
  };

  return (
    <div className="task-activity">
      <h5
        style={{
          fontSize: "12px",
          fontWeight: "600",
          marginBottom: 0,
          padding: "8px 0px 0px 10px",
        }}
      >
        Task Activity
      </h5>
      <div style={{ minHeight: "50vh", maxHeight: "200vh" }}>
        <Scrollbar sx={{ minHeight: "50vh", maxHeight: "200vh" }}>
          <Stack spacing={0.5} sx={{ padding: "8px 16px 8px 8px" }}>
            {/* No Activity Found */}
            {(!task?.activity_track?.track_list ||
              task?.activity_track?.track_list.length === 0) && (
              <div className="d-flex align-items-center">
                <Iconify
                  icon="ep:warning-filled"
                  sx={{ color: "#FFC107", marginRight: "5px" }}
                />
                <Typography
                  fontSize="12px"
                  color={(theme) => theme.palette.text.disabled}
                >
                  No activity found
                </Typography>
              </div>
            )}

            {/* Activity List */}
            {task?.activity_track?.track_list
              ?.filter((track) =>
                Boolean(
                  track.allow_members.find(
                    (id) => id === dispatch_get_user_profile().user_id
                  )
                )
              )
              .map((activity, i) => (
                <Stack
                  key={i}
                  direction="row"
                  spacing={1}
                  onClick={() => handleOpenDialog(activity)}
                  className="activity-stack"
                >
                  {/* <Iconify
                    icon="octicon:dot-fill-24"
                    color="#00ab55"
                    sx={{
                      height: "20px",
                      width: "20px",
                      flexShrink: 0,
                      zIndex: 2,
                    //   marginTop: "6px",
                    }}
                  /> */}
                  <div className="img-div-avtar">
                    <UserAvatar
                      sx={{
                        width: 16,
                        height: 16,
                        zIndex: 2,
                      }}
                      image={activity?.action_by?.image || " "}
                      alt={activity?.action_by?.first_name}
                    />
                  </div>
                  <Stack spacing={0.3}>
                    {activity.action_by ? (
                      <Typography fontSize="11px">
                        {getActivityText(activity)}
                      </Typography>
                    ) : (
                      <Typography fontSize="11px">{activity.text}</Typography>
                    )}

                    <Typography fontSize="9px">
                      {moment(activity.created_at).format("LLL")}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Scrollbar>
      </div>

      {/* Activity Information Dialog */}
      <ActivityInfoDialog
        open={openDialog}
        onClose={handleCloseDialog}
        activity={selectedActivity}
        // type={getActivityType(selectedActivity?.type)}
      />
    </div>
  );
}
