import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";
import { useSnackbar } from "notistack";

import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { PriorityPopover, StatusPopover, UserAvatar } from "src/components";
import { useAppContext } from "src/hooks";
import PriorityButton from "./PriorityButton";
import ActivityStatusButton from "./ActivityStatusButton";

export default function ActivityInfoDialog({ open, onClose, activity, type }) {
  console.log(activity, "Acti_vity");

  const handleClose = () => {
    onClose();
  };

  const getActivityText = (activity) => {
    if (activity.type === "priority_update") {
      return "update task priority.";
    } else if (activity.type === "target_date_update") {
      return "update task target date.";
    } else if (activity.type === "status_update") {
      return "update task status";
    } else if (activity.type === "task_update") {
      return "updated the task";
    } else if (activity.type === "team_update") {
      return "updated task team";
    } else if (activity.type === "attachments_update") {
      return "update the attachment";
    } else if (activity.type === "checklist_name_update") {
      return "update task checklist name";
    }
  };

  // activity types for full dialog display
  const allowedTypes = [
    "target_date_update",
    "status_update",
    "priority_update",
  ];
  const isAllowedType = allowedTypes.includes(activity?.type);

  return (
    <div className="task-activity-dialog">
      {activity && (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Activity Detail
              <IconButton size="small" onClick={handleClose}>
                <Iconify
                  icon="ion-close"
                  sx={{ height: "24px", width: "24px" }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ padding: "0px 20px 10px 20px" }}>
            {isAllowedType && activity.action_by.user_id && (
              <>
                <div className="mb-2">
                  <Typography fontSize="12px">
                    <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                      {`${activity.action_by.first_name} ${activity.action_by.last_name}`}
                    </span>
                    {getActivityText(activity)}
                  </Typography>
                </div>

                {/*activity by type ---------------------- */}
                <div>
                  {activity?.type === "target_date_update" && (
                    <div className="d-flex align-items-center gap-3">
                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Value
                        </Typography>
                        <Typography className="date-update" fontSize={"12px"}>
                          {moment(activity?.more_data?.old_data).format("LLL")}
                        </Typography>
                      </div>

                      <Iconify
                        icon="mdi:arrow-right"
                        className="arrow-icon-activity"
                      />

                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <Typography className="date-update" fontSize={"12px"}>
                          {moment(activity?.more_data?.new_data).format("LLL")}
                        </Typography>
                      </div>
                    </div>
                  )}

                  {activity?.type === "priority_update" && (
                    <div className="d-flex align-items-center gap-3">
                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Value
                        </Typography>
                        <PriorityButton
                          value={activity?.more_data?.old_data}
                          allowOpen={false}
                          onChange={() => {}}
                        />
                      </div>
                      <Iconify
                        icon="mdi:arrow-right"
                        className="arrow-icon-activity"
                      />
                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <PriorityButton
                          value={activity?.more_data?.new_data}
                          allowOpen={false}
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  )}

                  {activity?.type === "status_update" && (
                    <div className="d-flex align-items-center gap-3">
                      <div style={{ minWidth: "200px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Value
                        </Typography>
                        <ActivityStatusButton
                          value={activity?.more_data?.old_data}
                          allowOpen={false}
                        />
                      </div>
                      <Iconify
                        icon="mdi:arrow-right"
                        className="arrow-icon-activity"
                      />
                      <div style={{ minWidth: "200px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <ActivityStatusButton
                          value={activity?.more_data?.new_data}
                          allowOpen={false}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <hr style={{ color: "#d0d0d0" }} />

                {/* Action by ------------------- */}
                <div>
                  <Typography
                    fontSize={"10px"}
                    fontWeight={500}
                    paddingLeft={"3px"}
                  >
                    Action by
                  </Typography>
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{
                      backgroundColor: "#F6F7F8",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      width: "max-content",
                    }}
                  >
                    <UserAvatar
                      sx={{ width: 25, height: 25 }}
                      image={activity?.action_by?.image || " "}
                      alt={activity?.action_by?.first_name}
                    />
                    <Typography fontSize={"12px"}>
                      {activity?.action_by?.first_name}{" "}
                      {activity?.action_by?.last_name} {" - "}{" "}
                      {moment(activity?.created_at).format("LLL")}
                    </Typography>
                  </div>
                </div>
              </>
            )}

            {!activity.action_by.user_id && (
              <Stack direction={"row"} spacing={0.5}>
                <Iconify
                  icon="octicon:dot-fill-24"
                  color="#00ab55"
                  sx={{
                    height: "20px",
                    width: "20px",
                    flexShrink: 0,
                  }}
                />
                <Stack spacing={0.3}>
                  <Typography fontSize={"12px"}>{activity.text}</Typography>
                  <Typography fontSize={"9px"}>
                    {moment(activity.created_at).format("LLL")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
